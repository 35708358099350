import type { LoaderFunctionArgs, HeadersFunction, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Link } from '@remix-run/react'

import social from '@/assets/social.png?url'
import Logo from '@/components/Logo'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import VitessDiagram from '@/components/VitessDiagram'
import { buildRootUrl } from '@/url'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const rootUrl = buildRootUrl(request)

  return json({ rootUrl: rootUrl })
}

export const headers: HeadersFunction = ({ parentHeaders }) => ({
  'Cache-Control': parentHeaders.get('Cache-Control') ?? ''
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  const { rootUrl } = data || {}

  const title = 'The ultimate MySQL database platform — PlanetScale'
  const description =
    'PlanetScale is the world’s most advanced, fully-managed MySQL database platform. Scale, performance, and reliability, powered by Vitess.'
  const image = rootUrl ? `${rootUrl}${social}` : social

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:url', content: 'https://planetscale.com' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: 'https://planetscale.com' },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: 'https://planetscale.com' }
  ]
}

export default function Homepage() {
  return (
    <>
      <SiteHeader />

      <main className='container my-6 max-w-7xl px-3 sm:px-5 lg:px-12'>
        <section>
          <div className='flex flex-col gap-y-3'>
            <div>
              <h1>The database platform built for scale</h1>

              <p>
                PlanetScale is the world’s most scalable and reliable OLTP database. Built on Vitess, our cloud platform
                is trusted by some of the world’s largest brands. We offer a range of{' '}
                <a href='/docs/concepts/deployment-options'>deployment options</a> including multi-tenant cloud,
                single-tenant, or bring your own cloud account with{' '}
                <a href='/docs/enterprise/managed/overview'>PlanetScale Managed</a>.
              </p>

              <ul>
                <li>
                  <Link to='#vitess'>Vitess</Link>
                </li>
                <li>
                  <Link to='#uptime'>Uptime and reliability</Link>
                </li>
                <li>
                  <Link to='#performance'>Performance</Link>
                </li>
                <li>
                  <Link to='#security'>Security</Link>
                </li>
                <li>
                  <Link to='#insights'>Insights</Link>
                </li>
                <li>
                  <Link to='#platform'>Platform</Link>
                </li>
                <li>
                  <Link to='#support'>Support</Link>
                </li>
              </ul>
            </div>

            <div>
              <p>
                Our technology powers{' '}
                <Link prefetch='intent' to='/case-studies/cash-app'>
                  Tier 0 databases
                </Link>{' '}
                at:
              </p>

              <div className='mb-3 grid grid-cols-2 grid-rows-5 items-center justify-center md:grid-cols-5 md:grid-rows-2'>
                <Link
                  to='/case-studies/cash-app'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='block' ariaLabel='Block' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo name='block-darkmode' ariaLabel='Block' className='hidden max-h-12 w-3/4 max-w-20 dark:block' />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>
                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='etsy' ariaLabel='Etsy' className='max-h-12 w-3/4 max-w-20' />
                </div>
                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='intercom' ariaLabel='Intercom' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='intercom-darkmode'
                    ariaLabel='Intercom'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                </div>
                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='gusto' ariaLabel='Gusto' className='max-h-12 w-3/4 max-w-20' />
                </div>
                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='kick' ariaLabel='Kick' className='max-h-12 w-3/5 max-w-20' />
                </div>
                <Link
                  to='/case-studies/myfitnesspal'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='myfitnesspal' ariaLabel='MyFitnessPal' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='myfitnesspal-darkmode'
                    ariaLabel='MyFitnessPal'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>
                <Link
                  to='/case-studies/attentive'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='attentive' ariaLabel='Attentive' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='attentive-darkmode'
                    ariaLabel='Attentive'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>
                <div className='-m-[0.5px] flex h-16 items-center justify-center border md:h-22'>
                  <Logo name='slack' ariaLabel='Slack' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo name='slack-darkmode' ariaLabel='Slack' className='hidden max-h-12 w-3/4 max-w-20 dark:block' />
                </div>
                <Link
                  to='/case-studies/barstool-sports'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='barstool' ariaLabel='Barstool Sports' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='barstool-darkmode'
                    ariaLabel='Barstool Sports'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>

                <Link
                  to='/case-studies/community'
                  prefetch='intent'
                  className='group relative -m-[0.5px] flex h-16 items-center justify-center border transition hover:bg-gray-100/50 md:h-22 dark:hover:bg-black'
                >
                  <Logo name='community' ariaLabel='Community' className='max-h-12 w-3/4 max-w-20 dark:hidden' />
                  <Logo
                    name='community-darkmode'
                    ariaLabel='Community'
                    className='hidden max-h-12 w-3/4 max-w-20 dark:block'
                  />
                  <span className='absolute bottom-sm text-secondary opacity-0 transition group-hover:opacity-100'>
                    Read case study
                  </span>
                </Link>
              </div>
              <blockquote className='mb-1'>
                When you buy PlanetScale, you’re getting the technology and database expertise that ran and scaled
                YouTube, the internet’s #2 site, and the team that scaled GitHub to over 100M users globally.
              </blockquote>
              <p className='text-secondary'>– Todd Berman @Attentive</p>
            </div>

            <hr className='dot-fill my-3' />

            <div className='flex flex-col gap-y-3'>
              <div>
                <h2 id='vitess'>
                  <Link to='#vitess'>Vitess</Link>
                </h2>

                <p>
                  Vitess allows MySQL databases to scale horizontally through sharding. Enabling the distribution of
                  data and load across thousands of nodes, all presenting as a single database.
                </p>

                <p>
                  Vitess was developed at YouTube by the founders of PlanetScale to scale their main MySQL database to
                  petabytes of data on 70,000 nodes across 20 data centers. Now a CNCF-graduated open source project,
                  Vitess powers the databases of some of the web’s largest properties: Slack, HubSpot, Blizzard, Etsy,
                  GitHub, Block, Bloomberg, and Yelp.
                </p>

                <div className='mx-auto mb-3 text-center'>
                  <VitessDiagram />
                </div>

                <p>
                  Every time you spin up a PlanetScale database, a Vitess cluster is deployed under the hood giving you
                  scalability from day 1 to day 10000.
                </p>
                <blockquote className='mb-1'>
                  Our migration to Vitess is more than just a technological upgrade; it’s a strategic move to
                  future-proof our database architecture for the next decade and beyond.
                </blockquote>
                <p className='text-secondary'>– Ryan Sherlock @Intercom</p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='uptime'>
                  <Link to='#uptime'>Uptime and reliability</Link>
                </h2>

                <p>
                  We believe that uptime extends well beyond baseline operations, and we have made it our mission to
                  allow customers to manage their database on our platform without the need to ever take downtime:
                </p>

                <ul>
                  <li>
                    <a href='/docs/concepts/nonblocking-schema-changes'>Online DDL</a> - deploy all schema changes fully
                    online
                  </li>
                  <li>
                    <Link prefetch='intent' to='/blog/zero-downtime-migrations-at-petabyte-scale'>
                      Zero downtime database migrations/imports
                    </Link>
                  </li>
                  <li>
                    <Link prefetch='intent' to='/blog/introducing-global-replica-credentials'>
                      Directing traffic to new read-only replicas
                    </Link>
                  </li>
                  <li>
                    <a href='/docs/concepts/deploy-requests#revert-a-schema-change'>Schema reverts</a> (with no data
                    loss)
                  </li>
                  <li>MySQL and Vitess version updates</li>
                </ul>

                <p>
                  You can check out our track record on our{' '}
                  <a href='https://www.planetscalestatus.com/' rel='nofollow noopener noreferrer' target='_blank'>
                    status page
                  </a>
                  .
                </p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='performance'>
                  <Link to='#performance'>Performance</Link>
                </h2>
                <p>
                  With Vitess under the hood and our Global Edge Network on top, PlanetScale gives you incredible
                  performance at extreme scale. We are talking petabytes of data and millions of QPS.
                </p>
                <ul>
                  <li>
                    Maintain unlimited MySQL connections with{' '}
                    <Link prefetch='intent' to='/blog/connection-pooling'>
                      Vitess connection pooling
                    </Link>
                  </li>
                  <li>
                    Quickly and automatically{' '}
                    <Link prefetch='intent' to='/blog/introducing-global-replica-credentials'>
                      route queries to the closest replica
                    </Link>{' '}
                    with our Global Edge Network
                  </li>
                  <li>Leverage Vitess’s automatic query rewriting to improve poor query performance</li>
                  <li>
                    Improve write performance by spreading your data load across multiple MySQL instances via{' '}
                    <a href='/docs/sharding/overview'>sharding</a>
                  </li>
                </ul>
                <p>
                  Database performance involves more than just query and connection speed. Backups, schema changes, and
                  version upgrades are all handled by PlanetScale and are built to be fast with no impact to your
                  database performance.
                </p>

                <p>
                  Insights schema recommendations proactively alert you of missing indexes or anomalous queries,
                  allowing you to continuously improve the performance of your database.
                </p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='security'>
                  <Link to='#security'>Security</Link>
                </h2>

                <p>
                  PlanetScale’s core infrastructure was built to comply with high standards of security, compliance, and
                  privacy.
                </p>

                <ul>
                  <li>SOC 2 Type 2+ HIPAA compliance</li>
                  <li>PCI DSS 4.0 compliance as a Level 1 Service Provider (for Managed deployments on AWS)</li>
                  <li>
                    Fully private network isolation and third-account customer-controlled public key infrastructure for
                    AWS Managed deployments
                  </li>
                  <li>
                    PlanetScale databases and their client communications are AES encrypted throughout the PlanetScale
                    platform, both in transit and at rest.
                  </li>
                  <li>Private database connectivity through AWS PrivateLink or GCP Private Service Connect</li>
                  <li>Option to deploy in your own cloud with PlanetScale Managed (AWS or GCP)</li>
                  <li>Data Processing Addendum available</li>
                  <li>SSO available on all plans</li>
                  <li>HIPAA Business Associate Agreements available on all plans</li>
                  <li>Security and audit logs</li>
                </ul>

                <p>
                  Learn more about our security and compliance practices in the{' '}
                  <a href='/docs/concepts/security'>security documentation</a>.
                </p>

                <blockquote className='mb-1'>
                  We have very strict regulatory requirements that can feel painful to the average engineer, however
                  PlanetScale was a strong partner in grinding through our asks, leaving us in a place where everyone
                  was happy.
                </blockquote>
                <p className='text-secondary'>– Aaron Young @Block</p>

                <p>
                  Visit our{' '}
                  <a
                    href='https://app.conveyor.com/profile/planetscale'
                    rel='nofollow noopener noreferrer'
                    target='_blank'
                  >
                    Trust Center
                  </a>{' '}
                  to request the latest copy of our SOC 2 Type 2 report, PCI DSS Attestation of Compliance, and more.
                </p>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='insights'>
                  <Link to='#insights'>Insights</Link>
                </h2>

                <ul>
                  <li>
                    Identify queries that are running too often, too long, returning too much data, producing errors,
                    and more with <a href='/docs/concepts/query-insights'>PlanetScale Insights</a>.
                  </li>
                  <li>Actionable recommendations to improve your schema</li>
                  <li>Review index usage</li>
                  <li>Automatically detect anomalies in your database</li>
                </ul>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='platform'>
                  <Link to='#platform'>Platform</Link>
                </h2>
                <p>
                  All of our features are designed to provide full end-to-end database management with no downtime ever,
                  built-in checks to prevent human error, full insight into query performance, and actionable
                  recommendations to make your database faster.
                </p>
                <ul>
                  <li>
                    Branching and deploy requests for zero downtime schema changes that your team can review/approve.
                  </li>
                  <li>
                    Store your <a href='/docs/vectors/overview'>vector data</a> alongside your application’s relational
                    MySQL data with PlanetScale vector support. We support transactional operations, ensure data
                    consistency, and efficiently manage vector indexes at terabyte-scale.
                  </li>
                  <li>
                    Roll back bad schema changes with no downtime and no data loss. With{' '}
                    <Link prefetch='intent' to='/blog/behind-the-scenes-how-schema-reverts-work'>
                      VReplication under the hood
                    </Link>
                    , we keep a copy of your previous schema and keep the incoming data in sync with the new schema. If
                    you made a mistake, all we have to do is swap the tables back.
                  </li>
                  <li>An interactive Insights dashboard to give you a detailed look at all active queries.</li>
                  <li>Horizontally shard your tables with our cluster configuration panel.</li>
                  <li>
                    <Link
                      prefetch='intent'
                      to='/blog/gated-deployments-addressing-the-complexity-of-schema-deployments-at-scale'
                    >
                      Gated deployments
                    </Link>{' '}
                    allow you to run schema changes, but control when you do the final cutover, which is helpful for
                    long-running schema changes.
                  </li>
                  <li>
                    <a href='/docs/reference/planetscale-cli'>CLI</a> and{' '}
                    <a href='/docs/concepts/planetscale-api-oauth-applications'>API</a> so you can tailor your workflow
                    to exactly what works best for your team.
                  </li>
                  <li>Utilize our Global Edge Network to automatically route reads to the closest replica.</li>
                  <li>
                    Integrations with Fivetran, Airbyte, Hightouch, Datadog, Vantage, Debezium,{' '}
                    <a href='/docs/concepts/planetscale-connect'>and more</a>.
                  </li>
                </ul>
              </div>

              <hr className='dot-fill my-3' />

              <div>
                <h2 id='support'>
                  <Link to='#support'>Support</Link>
                </h2>
                <p>
                  PlanetScale Support goes well beyond what you might have experienced with other providers. We partner
                  closely with our customers whether they are launching a AAA game or planning new architecture. Even
                  with the standard tier, you can expect timely, and thorough responses from engineers. We also have an
                  active Discord channel where you can chat with staff as well as a{' '}
                  <a href='https://github.com/planetscale/discussion/discussions'>GitHub Discussion board</a> for
                  questions.{' '}
                </p>
                <p>
                  If you’re looking into Vitess or already running it on your own, the security of knowing you have a
                  direct line to the core maintainers and co-creators of Vitess is invaluable. With the PlanetScale
                  Enterprise support tier, you get:
                </p>
                <ul>
                  <li>Private shared Slack channel between PlanetScale and your company</li>
                  <li>15 minutes (24x7) P1 Urgent response times</li>
                  <li>Hands-on proactive migration assistance</li>
                  <li>Sharding scheme design support from our Solutions team</li>
                  <li>Query pattern analysis to plan for optimal performance</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>

      <SiteFooter />
    </>
  )
}
