export default function VitessDiagram() {
  return (
    <>
      <pre className='inline-block bg-primary text-[12px] leading-4 sm:hidden'>{`
   ┌────────────────────────────────┐   
   │░░░░░░░░░░░░░VTGate░░░░░░░░░░░░░│   
   └────────────────────────────────┘   
                    │                   
                                        
          ─ ─ ─ ─ ─ ┴ ─ ─ ─ ─ ┐         
         │                              
                              │         
         ▼                    ▼         
   ╔═══════════╗        ╔═══════════╗   
   ║           ║        ║           ║   
   ║  Primary  ║        ║  Primary  ║   
   ║           ║        ║           ║   
   ╚═══════════╝        ╚═══════════╝   
         │                    │         
                                        
     ─ ─ ┴ ─ ─            ─ ─ ┴ ─ ─     
    │         │          │         │    
    ▼         ▼          ▼         ▼    
┌───────┐ ┌───────┐  ┌───────┐ ┌───────┐
│Replica│ │Replica│  │Replica│ │Replica│
└───────┘ └───────┘  └───────┘ └───────┘
`}</pre>
      <pre className='hidden bg-primary text-[12px] leading-4 sm:inline-block md:hidden lg:text-base lg:leading-5'>{`
           ┌──────────────────────────────────┐            
           │░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│            
           │░░░░░░░░░░░░░░VTGate░░░░░░░░░░░░░░│            
           │░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│            
           └──────────────────────────────────┘            
                             │                             
                                                           
         ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┼ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐         
                                                           
         │                   │                   │         
         ▼                   ▼                   ▼         
   ╔═══════════╗       ╔═══════════╗       ╔═══════════╗   
   ║           ║       ║           ║       ║           ║   
   ║  Primary  ║       ║  Primary  ║       ║  Primary  ║   
   ║           ║       ║           ║       ║           ║   
   ╚═══════════╝       ╚═══════════╝       ╚═══════════╝   
         │                   │                   │         
                                                           
     ─ ─ ┴ ─ ─           ─ ─ ┴ ─ ─           ─ ─ ┴ ─ ─     
    │         │         │         │         │         │    
    ▼         ▼         ▼         ▼         ▼         ▼    
┌───────┐ ┌───────┐ ┌───────┐ ┌───────┐ ┌───────┐ ┌───────┐
│Replica│ │Replica│ │Replica│ │Replica│ │Replica│ │Replica│
└───────┘ └───────┘ └───────┘ └───────┘ └───────┘ └───────┘
`}</pre>
      <pre className='hidden bg-primary text-[12px] leading-4 md:inline-block lg:text-base lg:leading-5'>{`
               ┌─────────────────────────────────────────────────────────┐               
               │░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│               
               │░░░░░░░░░░░░░░░░░░░░░░░░░VTGate░░░░░░░░░░░░░░░░░░░░░░░░░░│               
               │░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░│               
               └─────────────────────────────────────────────────────────┘               
                                            │                                            
                                                                                         
              ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┼ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─              
             │                                                             │             
             ▼                              ▼                              ▼             
   ╔═══════════════════╗          ╔═══════════════════╗          ╔═══════════════════╗   
   ║                   ║          ║                   ║          ║                   ║   
   ║                   ║          ║                   ║          ║                   ║   
   ║      Primary      ║          ║      Primary      ║          ║      Primary      ║   
   ║                   ║          ║                   ║          ║                   ║   
   ║                   ║          ║                   ║          ║                   ║   
   ╚═══════════════════╝          ╚═══════════════════╝          ╚═══════════════════╝   
             │                              │                              │             
      ┌ ─ ─ ─ ─ ─ ─ ┐                ┌ ─ ─ ─ ─ ─ ─ ┐                ┌ ─ ─ ─ ─ ─ ─ ┐      
      ▼             ▼                ▼             ▼                ▼             ▼      
┌───────────┐ ┌───────────┐    ┌───────────┐ ┌───────────┐    ┌───────────┐ ┌───────────┐
│           │ │           │    │           │ │           │    │           │ │           │
│  Replica  │ │  Replica  │    │  Replica  │ │  Replica  │    │  Replica  │ │  Replica  │
│           │ │           │    │           │ │           │    │           │ │           │
└───────────┘ └───────────┘    └───────────┘ └───────────┘    └───────────┘ └───────────┘
`}</pre>
    </>
  )
}
